import React, {useState} from 'react';
import main from "./Images/clark2.jpg";
import about3 from "./Images/bathtub.jpg";
import about5 from "./Images/chillClark.jpg";
import "./Images/blizzard.jpg";
import "./about.css";
import LoadingSkeleton from "../../LoadingSkeleton";

export const AboutMePage = () => {
    const [loadedImages, setLoadedImages] = useState(0);

    const onImageLoad = () => {
        setLoadedImages(prevState => prevState + 1);
    }

    const layout1 = () => (
        <div className={"displayNone"}>
            {[
                {
                    src: main,
                    alt: main,
                    description: 'A Clarksville native who has been making waves throughout the city since 2014. The Journey began for Clark at the age of six when his father gifted him his first camera which was a Kodak DX3500.'
                },
                {
                    src: about3,
                    alt: about3,
                    description: 'Gaining a head start over his competitors at an early age, Clark has had a generous amount of time to refine and develop his knowledge, skills, and talent with the camera.'
                },
                {
                    src: about5,
                    alt: about5,
                    description: 'Known for pursuing the perfect shot with the camera, This drive to capture life\'s best moments in breathtaking HD has earned Clark a reputation as an artistic visionary.'
                },
            ].map(({src, alt, description}) => (
                <React.Fragment key={src}>
                    <div className={"aboutMeContainer"}>
                        <p>{description}</p>
                    </div>
                    <img
                        src={src}
                        alt={alt}
                        className={"aboutMeImages"}
                        onLoad={onImageLoad}
                    />
                    {loadedImages !== 3 && (
                        <LoadingSkeleton/>
                    )}
                </React.Fragment>
            ))}
        </div>
    );

    const cardLayout = () => (
        <div className={"row showCardLayout"}>
            {[
                {
                    src: main,
                    description: 'A Clarksville native who has been making waves throughout the city since 2014. The Journey began for Clark at the age of six when his father gifted him his first camera which was a Kodak DX3500.'
                },
                {
                    src: about3,
                    description: 'Gaining a head start over his competitors at an early age, Clark has had a generous amount of time to refine and develop his knowledge, skills, and talent with the camera.'
                },
                {
                    src: about5,
                    description: 'To compliment his natural talent, Clark has also developed a reputation as a friendly, relaxed, respectful, and highly professional individual in the community.'
                },
            ].map(({src, description}) => (
                <div className="column" key={src}>
                    <div className="cardAboutMe">
                        <img src={src} alt={src} className={"cardImg"}/>
                        <div className="container">
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className={"grayBackground"}>
            <div className="aboutImage">
                <div className="hero-textMain">
                    <h1 className={"hero"}>Who is Clark Cold ?</h1>
                </div>
            </div>
            {layout1()}
            {cardLayout()}
        </div>
    );
};

export default AboutMePage;

import React from 'react';
import './App.css';

import {Redirect, Route, Switch} from 'react-router-dom';

import Navbar from './component/Header/Navbar';
import Main from './component/Main/main';
import Footer from './component/Footer/Footer';
import Booking from './component/Pages/Booking/BookingPage';
import MusicVideos from './component/Pages/MusicVideos/MusicVideos';
import AboutMePage from './component/Pages/AboutPage/Aboutpage';
import {ThemeProvider} from "@mui/material";
import theme from "./theme";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <div>
                <Navbar/>
                <Switch>
                    <Route path="/" exact component={Main}/>
                    <Route path="/booking" exact component={Booking}/>
                    <Route path="/videos" exact component={MusicVideos}/>
                    <Route path="/about" exact component={AboutMePage}/>
                    <Redirect to="/"/>
                </Switch>
                <Footer/>
            </div>
        </ThemeProvider>
    );
};

export default App;

import React from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";

const CustomDatePicker = ({value, setValue, error, setError, isDesktop}) => {
    const handleChange = (newValue) => {
        setValue(newValue);
        const dateValue = newValue ? newValue.toDate() : null; // convert Day.js object to Date object
        if (dateValue === null || dateValue === undefined || !(dateValue instanceof Date)) {
            setError("date", {message: "Date is required"});
        } else {
            setError("date");
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={isDesktop ? {
                    width: '58%',
                    height: '4rem',
                    '& .MuiIconButton-root': {
                        width: '4rem',
                        height: '4rem',
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        },
                    },
                } : {
                    width: '100%',
                    height: '4rem',
                    '& .MuiIconButton-root': {
                        width: '4rem',
                        height: '4rem',
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        },
                    },
                }}
                label="Tentative Appointment Date"
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                    />
                )}
                value={value}
                onChange={handleChange}
                minDate={dayjs()}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;

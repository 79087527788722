import React, {useEffect, useRef, useState} from "react";
import "./home.css";
import LoadingSkeleton from "../../LoadingSkeleton";
import mainImage from "./ArtWithClark/Main/Clark-main.jpg";

const importAll = (r) => {
    return r.keys().map(r);
};

export const Home = () => {
    const [isGalleryLoaded, setIsGalleryLoaded] = useState(false);
    const [images, setImages] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    const heroRef = useRef(null);

    const renderMainImage = () => {
        return (
            <img
                src={mainImage}
                alt="business owner"
                className="businessOwnersImage"
            />
        );
    };

    const renderHeroBanner = () => {
        return (
            <>
                <div className="hero-image" ref={heroRef}>
                    <div className="hero-textMain">
                        <h1 className="hero">I am Clark Cold</h1>
                        <p>A Creative Visionary focused on capturing the world through my lens</p>
                    </div>
                </div>

                {!isVisible && <LoadingSkeleton/>}
            </>
        );
    };

    const renderGallery = () => {
        return (
            <div className="squareTileContainer">
                {isVisible &&
                    images.map((img, index) => (
                        <React.Fragment key={index}>
                            {isGalleryLoaded && (
                                <img
                                    src={img}
                                    alt={`model ${index + 1}`}
                                    className="gallery-img-dimensions"
                                />
                            )}
                            {!isGalleryLoaded && (
                                <LoadingSkeleton key={index} name="skeleton-gallery"/>
                            )}
                        </React.Fragment>
                    ))}
            </div>
        );
    };

    const renderSpace = () => {
        return <div className="navBarFiller"></div>;
    };

    useEffect(() => {
        const imagesArr = importAll(
            require.context("./ArtWithClark", false, /\.(png|jpe?g|svg)$/)
        );
        setImages(imagesArr);
        let loadedImages = 0;
        imagesArr.forEach((img) => {
            const imageObj = new Image();
            imageObj.src = img;
            imageObj.onload = () => {
                loadedImages++;
                if (loadedImages === imagesArr.length) {
                    setIsGalleryLoaded(true);
                }
            };
        });

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {threshold: 0.5}
        );

        observer.observe(heroRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className="">
            {renderSpace()}
            {renderMainImage()}
            {renderHeroBanner()}
            {renderGallery()}
        </div>
    );
};

export default Home;

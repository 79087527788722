import React, {useState} from 'react';
import "./booking.css"
import {useForm} from 'react-hook-form';
import mainPhoto from "./shoot2.jpg";
import {yupResolver} from '@hookform/resolvers/yup';
import emailjs from '@emailjs/browser';
import * as Yup from "yup";
import sentIcon from "./icons8-camera-64.png"
import dayjs from 'dayjs';
import CustomDatePicker from "../../customDatePicker";
import {useMediaQuery} from "@mui/material";


require("yup-phone");

const SERVICE_ID = 'service_z1dljna';
const TEMPLATE_ID = 'template_15h15cx';
const PUBLIC_KEY = 'PzJGRMipbg7Y8BuJm';

export const BookingPage = () => {
    const [isBannerLoaded, setIsBannerLoaded] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [value, setValue] = useState(null); // set initial value to null
    const isDesktop = useMediaQuery('(min-width: 600px)');

    const validationSchema = Yup.object().shape({
        fullname: Yup.string().required('Full Name is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Please provide a valid Email'),
        phone: Yup.string()
            .phone("US", "Please enter a valid phone number")
            .required("Phone number is required"),
        textArea: Yup.string()
            .required('Description is required')
            .min(30, 'Please add a little more detail for the best experience'),
        date: Yup.date()
    });

    const {
        register, handleSubmit, setError, formState: {errors}
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data) => {
        if (value !== null) {
            const dateValue = dayjs(value).format("dddd, MMMM D, YYYY");
            const formData = {...data, date: dateValue};
            emailjs
                .send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_KEY)
                .then(
                    () => {
                        setIsFormSubmitted(true);
                    },
                    (error) => {
                        console.error(error.text);
                        setIsFormSubmitted(false);
                    }
                );
        } else {
            setError("date", {message: "Please select a tentative date"});
        }
    };


    const handleImageLoad = () => {
        setIsBannerLoaded(true);
    };

    return (
        <div className="" style={{backgroundColor: 'white'}}>
            <div className="twoCol">
                <div className="col1">
                    <div className="bookingCoverImageContainer">
                        <img
                            src={mainPhoto}
                            alt=""
                            className={`bookingCoverImage ${isBannerLoaded ? "loaded" : ""}`}
                            onLoad={handleImageLoad}
                        />
                    </div>
                </div>
                <div className="col2">
                    <div className="register-form">
                        <form onSubmit={handleSubmit(onSubmit)} className="contact1-form validate-form">
                            <h1 className="contact1-form-title hero">
                                The Journey Starts Here
                            </h1>

                            {[
                                {name: 'fullname', type: 'text', placeholder: 'Full Name'},
                                {name: 'email', type: 'email', placeholder: 'Email'},
                                {name: 'phone', type: 'text', placeholder: 'Phone', id: 'phone'}
                            ].map(({name, type, placeholder, id}) => (
                                <div className="form-group" key={name}>
                                    <input
                                        disabled={isFormSubmitted}
                                        placeholder={placeholder}
                                        name={name}
                                        type={type}
                                        id={id}
                                        {...register(name)}
                                        className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors[name]?.message}</div>
                                </div>
                            ))}

                            <div className="form-group" id={"text-form"}>

                            <textarea className={`form-control ${errors.textArea ? 'is-invalid' : ''}`}
                                      name="textArea"
                                      disabled={isFormSubmitted}
                                      placeholder={"Please provide a brief description of your vision"}
                                      {...register('textArea')}
                            ></textarea>
                                <div className="invalid-feedback">{errors.textArea?.message}</div>
                            </div>

                            <div className="form-group" id={"scheduler"}>
                                <CustomDatePicker
                                    value={value}
                                    setValue={setValue}
                                    error={errors.date}
                                    setError={setError}
                                    isDesktop={isDesktop}
                                />

                                {errors.date && (
                                    <div className="invalid-feedback">{errors.date.message}</div>
                                )}
                            </div>


                            {isFormSubmitted ? (
                                <div className="deliveredMargin">
                                    <div className="delivered">
                                        I'll be in Touch Soon &nbsp;
                                    </div>
                                    <img src={sentIcon} alt={"sentIcon"}/>
                                </div>
                            ) : (
                                <div className="container-contact1-form-btn">
                                    <button className="contact1-form-btn">
                                    <span style={{"fontSize": "2rem"}}>
                                        Contact Me
                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    </span>
                                    </button>
                                </div>
                            )}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BookingPage;


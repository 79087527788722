import React, {useState} from "react";
import "./Header.css";
import logo from "./ClarkColdVisuals-Logo-White.png";
import {Link} from "react-router-dom";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function handleLinkClick() {
        setIsMenuOpen(false);
    }

    function handleMenuClick() {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <header className={isMenuOpen ? "active" : ""}>
            <div className="container2">
                <nav>
                    <ul className="nav-list nav-list-mobile">
                        <li className="nav-item">
                            <div className="mobile-menu" onClick={handleMenuClick}>
                                <span className="line line-top"></span>
                                <span className="line line-middle"></span>
                                <span className="line line-bottom"></span>
                            </div>
                        </li>

                        <li className="companyLogo logoContainer">
              <span>
                <Link to="/">
                  <img
                      className="logo"
                      src={logo}
                      alt={"Clark Cold Visuals Logo"}
                  ></img>
                </Link>
              </span>
                        </li>

                        <li className="nav-item"></li>
                    </ul>
                    {/* <!-- /.nav-list nav-list-mobile -->  */}
                    <ul className="nav-list nav-list-larger">
                        <li className="nav-item nav-item-hidden">
                            <Link
                                to="/"
                                className="nav-link"
                                style={{display: "none"}}
                            >
                                empty line
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/" className="nav-link" onClick={handleLinkClick}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/videos"
                                className="nav-link"
                                onClick={handleLinkClick}
                            >
                                Music Videos
                            </Link>
                        </li>

                        <li className="companyLogo logoContainer hideLogo">
              <span>
                <Link to="/">
                  <img
                      className="logo"
                      src={logo}
                      alt={"Clark Cold Visuals Logo"}
                  ></img>
                </Link>
              </span>
                        </li>

                        <li className="nav-item">
                            <Link to="/about" className="nav-link" onClick={handleLinkClick}>
                                About Me
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/booking"
                                className="nav-link"
                                onClick={handleLinkClick}
                            >
                                Book Now
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;

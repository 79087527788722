import React from 'react';
import Home from "./Home/home";

const Main = () => {
    return (
        <div>
            <Home/>
        </div>
    )
}


export default Main;
import React from "react";
import "./LoadingSkeleton.css"

export const LoadingSkeleton = ({name, height = '100%'}) => {
    return (
        <div
            className={`skeleton-img ${name}`}
            style={{width: "100%", height: height}}
        />
    );
};


export default LoadingSkeleton;
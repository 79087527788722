import React, {Component} from "react"

import './Footer.css'
import './Toggel.js'

class Footer extends Component {
    render() {
        return (
            <footer className="footer-wrapper">
                <div className="container justifyCenter">
                    <div className="row marginBottom">
                        <div className=" col-sm-12 order-2 col-md-12 col-lg-12">
                            Copyright &copy; {new Date().getFullYear()} Ron Harper. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
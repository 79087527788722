import React, {useEffect, useState} from 'react';
import "./musicVideos.css"
import LoadingSkeleton from "../../LoadingSkeleton";

const VIDEOS = [
    {
        id: "Geni Lambdorghini",
        title: "Genie Lamborghini ft. Bancr60ll, Chuck Tay - If You Tried",
        src: "https://www.youtube.com/embed/Tq5Yhx1Edlw?controlls=0"
    },
    {
        id: "ARMAGEDDON",
        title: "Thunderground - ARMAGEDDON",
        src: "https://www.youtube.com/embed/GEN9w8hp11E?controlls=0"
    },
    {
        id: " Big Dog Kitchen",
        title: "Chef Lindo - Big Dog Kitchen",
        src: "https://www.youtube.com/embed/E_vk0Zaxres?controlls=0"
    },
    {
        id: "Moonlight",
        title: "M.T. Lebow$ki - Moonlight",
        src: "https://www.youtube.com/embed/8JoxmkReS98?controlls=0"
    },
    {
        id: "MW4!! / G2G",
        title: "'93RAMO - MW4!! / G2G!!",
        src: "https://www.youtube.com/embed/niIQ4SFDauE?controlls=0"
    },
    {
        id: "2020 Nissan Sentra",
        title: "Quincy Foster - 2020 Nissan Sentra",
        src: "https://www.youtube.com/embed/DkM_0JJoxO8?controlls=0"
    },
]

const MusicVideos = () => {
    const [mainVidSrc, setMainVidSrc] = useState(VIDEOS[0].src);
    const [isVisible, setIsVisible] = useState(false);
    const [isBannerLoaded, setIsBannerLoaded] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const onVideoClick = (src) => {
        if (src !== mainVidSrc) {
            setMainVidSrc(src);
            setIsBannerLoaded(false);
        }
    };


    const renderNowPlayingVideoContainer = () => {
        return (
            <div className="main-video-container">
                {isVisible && (
                    <div style={{display: isBannerLoaded ? 'block' : 'none'}}>
                        <iframe
                            className="main-video"
                            src={mainVidSrc}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                            onLoad={() => {
                                setIsBannerLoaded(true);
                            }}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                )}

                {!isBannerLoaded && (
                    <div style={{display: isVisible ? 'block' : 'none'}}>
                        <LoadingSkeleton name={""}/>
                    </div>
                )}

                <div style={{display: 'none'}}>
                    <iframe title={"main video"} src={mainVidSrc} onLoad={() => setIsBannerLoaded(true)}/>
                </div>
            </div>
        )
    }

    const renderVideoListContainer = () => {
        return (
            <div className="video-list-container">
                {isInitialLoad && !isVisible && (
                    <LoadingSkeleton name={"video list"}/>
                )}
                {!isInitialLoad && isVisible && VIDEOS.map((video, index) => (
                    <div key={index} className={`list ${mainVidSrc === video.src ? 'active' : ''}`}
                         onClick={() => onVideoClick(video.src)}>
                        <iframe
                            className="list-video"
                            src={video.src}
                            title={video.id}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>

                        </iframe>
                        <h3 className="list-title">{video.title}</h3>
                    </div>
                ))}
            </div>
        )
    }

    const renderMusicVideoLayout = () => {
        return (
            <div className="container-v">
                {renderNowPlayingVideoContainer()}
                {renderVideoListContainer()}
            </div>
        )
    }

    const renderSpace = () => {
        return <div className="navBarFiller2"></div>;
    };

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
            setIsInitialLoad(false);
        }, 0)
    }, [])

    return (
        <div className={"grayBackground"}>
            {renderSpace()}
            {renderMusicVideoLayout()}
        </div>
    )
}

export default MusicVideos;

import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    fontSize: '1.5rem',
                },
            },
        },

        MuiPickersCalendarHeader: {
            styleOverrides: {
                switchHeader: {
                    fontSize: '1.5rem',
                },
                dayLabel: {
                    fontSize: '1.2rem',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },

            },
        },


    },
});

export default theme;
